<template>
    <div id="content" class="d-flex">
        <div class="m-auto text-center">
            <img :src="logo" class="img-fluid">
            <h1>NOSOTROS SOMOS<br>
                PASIÓN E INGENIO</h1>
        </div>
    </div>


</template>

<script>
    export default {
        computed: {
            logo:()=> {return require('../../assets/peruapps.png')}
        }
    }
</script>

<style scoped lang="scss">
    #content{
        min-height: 80vh;
    }
</style>
